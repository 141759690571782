import React, { useState } from 'react'
import styled from 'styled-components'
import AppBar from '@mui/material/AppBar'
import useMediaQuery from '@mui/material/useMediaQuery'
import InfoIcon from '@mui/icons-material/Info'

import UserMenu from '../../molecules/UserMenu'
import Logo from '../../atoms/Logo'
import TitleNavBar, {
  SubTitleNavbar
} from '../../atoms/Typography/TitleNavBar'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import SearchTracking from '../SearchTracking/SearchTracking'
import InstallApp from '../../../pwa/InstallApp'
import UpdateApp from '../../../pwa/UpdateApp'
import Navigate from '../../atoms/Navigate'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { resultDeliveryEvent } from '../../../../domain/features/deliveryEvent/getDeliveryEvent'
import ButtonBack from '../../atoms/ButtonBack'
import { useScroll, animated } from '@react-spring/web'
import Fade from '@mui/material/Fade'
import BadgetStatus from '../../molecules/BadgetStatus'

const TitleTracking = styled(TitleNavBar)`
  font-size: 14px;
  line-height: 16px;
`
const SutbTitleTracking = styled(SubTitleNavbar)`
  @media (max-width: 1100px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 700px) {
    font-size: 11px;
    line-height: 12x;
  }
`
const SutbTitleClient = styled(SutbTitleTracking)`
  @media (max-width: 1100px) {
    font-size: 10px;
    line-height: 12px;
  }
  @media (max-width: 700px) {
    font-size: 8px;
    line-height: 10x;
  }
`
const ButtonBackTracking = styled(ButtonBack)`
  margin: 0px;
`

const Row = styled('div')`
  display: flex;
  flex-direction: row;
`

const RowTracking = styled(Row)`
  justify-content: baseline;
  align-items: center;
`

const ContainerTitle = styled('div')`
  display: flex;
  flex-direction: column;
`

const NavigateImage = styled(Navigate)`
  text-decoration: none;
  text-decoration-line: none;
  padding: 0;
  margin: 0;
`

const InfoSearch = styled(InfoIcon)`
  color: ${({ theme }) => theme.palette.info.main};
  margin-left: 15px;
  cursor: context-menu;
  @media (max-width: 1100px) {
    margin-left: 0px;
  }
`

const HeaderBar = styled(animated(AppBar))`
  background-color: ${({ theme }) => theme.palette.info.dark};
  height: 60px;
  padding-left: 4%;
  padding-right: 3%;

  @media (max-width: 1100px) {
    padding-left: 3%;
    padding-right: 3%;
  }
  @media (max-width: 700px) {
    padding-right: 1%;
    padding-left: 1%;
  }
`

const ToolbarContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`
ToolbarContainer.displayName = 'ToolbarContainer'

const ToolbarLeft = styled(ToolbarContainer)`
  flex: 1;
  justify-content: flex-start;
`
ToolbarLeft.displayName = 'ToolbarLeft'

const ToolbarRight = styled(ToolbarContainer)`
  justify-content: flex-end;
`
ToolbarRight.displayName = 'ToolbarRight'

const ToolbarCenter = styled(ToolbarContainer)`
  justify-content: center;
  flex: 2;
  margin-left: 1%;
  margin-right: 1%;
  margin: 0px;
  @media (max-width: 1100px) {
    margin: 0px;
    flex: 4;
    width: 100%;
  }
`
ToolbarCenter.displayName = 'ToolbarCenter'

const Navbar = () => {
  const params = useParams()
  const result = useSelector(resultDeliveryEvent)

  const [showTitle, setShowTitle] = useState(false)
  const { scrollYProgress } = useScroll({
    onChange: ({ value: { scrollY } }) => {
      if (scrollY > 60) {
        setShowTitle(true)
      } else {
        setShowTitle(false)
      }
    },
    default: {
      immediate: true
    }
  })

  const match = useMediaQuery('(min-width:1100px)')
  const mobile = useMediaQuery('(min-width:700px)')
  const { state } = useLocation()
  return (
    <HeaderBar
      position="sticky"
      elevation={1}
      color="transparent"
      data-testid="Navbar__Header--bar"
      style={scrollYProgress}
    >
      <ToolbarContainer>
        <ToolbarLeft>
          {showTitle && params?.trackingId ? (
            <Fade
              in={showTitle}
              timeout={{ appear: 800, enter: 1000, exit: 800 }}
            >
              <RowTracking>
                <ButtonBackTracking />
                <ContainerTitle>
                  {match && (
                    <TitleTracking>
                      {params?.trackingId
                        ? `Despacho ${params?.trackingId}`
                        : 'Despacho no asignado'}
                    </TitleTracking>
                  )}
                  <SutbTitleTracking>
                    {!match ? 'Pedido' : result[0]?.idType || 'Pedido'}{' '}
                    {state.orderId || result[0]?.orderId}
                  </SutbTitleTracking>
                  {mobile && (
                    <SutbTitleClient>
                      {state.clientName ||
                        state?.customer?.customer_name ||
                        result[0]?.clientName ||
                        result[0]?.customer_name ||
                        result[0]?.customer?.customer_name}
                    </SutbTitleClient>
                  )}
                </ContainerTitle>
              </RowTracking>
            </Fade>
          ) : (
            <Row>
              <NavigateImage
                to="/"
                data-testid="ToolbarLeft__Navigate--Logo"
              >
                <Logo />
              </NavigateImage>

              <ContainerTitle>
                <TitleNavBar>Tracking</TitleNavBar>
                <SubTitleNavbar>Seguimiento de pedidos</SubTitleNavbar>
              </ContainerTitle>
            </Row>
          )}
        </ToolbarLeft>
        <ToolbarCenter>
          <SearchTracking />
          {match ? (
            <Tooltip
              role="tooltip"
              title="Busca por información de cliente o pedido"
              placement="bottom-start"
              followCursor
            >
              <InfoSearch data-testid="InfoSeach_Icon" />
            </Tooltip>
          ) : null}
        </ToolbarCenter>

        <ToolbarRight>
          {showTitle && match && (
            <div>
              <BadgetStatus
                status={state?.conditionOffice || result[0]?.statusCode}
                size="small"
              />
            </div>
          )}
          <UserMenu />
          <InstallApp />
          <UpdateApp />
        </ToolbarRight>
      </ToolbarContainer>
    </HeaderBar>
  )
}
export default Navbar
