/**
 * Mapa de frases que relaciona los tipos de envío con sus respectivos códigos.
 * @type {Map<string, number>}
 */
const phrasesMap = new Map([
  ['Retiro en Tienda', 10],
  ['Despacho a Domicilio', 12],
  ['Envío desde Tienda', 11]
])

/**
 * Retorna el código asociado a un tipo de envío.
 *
 * @param {Map<string, number>} phrasesMap - Mapa que contiene las frases y códigos asociados.
 * @param {number} [defaultCode=12] - Código por defecto en caso de que el tipo de envío no coincida con ninguna frase.
 * @returns {function(string): number} - Función que toma un tipo de envío y retorna el código correspondiente.
 */
const getOneShippingTypeCode =
  (phrasesMap, defaultShippingType = 'Despacho a domicilio') =>
  (shippingType = '') => {
    const type = shippingType?.split(' - ')[0]
    return phrasesMap.get(type) || phrasesMap.get(defaultShippingType)
  }

/**
 * Función curried para obtener el código del tipo de envío, utilizando el mapa de frases y un código por defecto.
 *
 * @constant
 * @type {function(string): number}
 * @defaultCode 12 - Código por defecto en caso de que no coincida ninguna frase.
 * @description Esta constante almacena una función curried que toma un tipo de envío y retorna el código correspondiente según el mapa `phrasesMap`.
 */
const getshippingTypeCode = getOneShippingTypeCode(phrasesMap, 12)

export default getshippingTypeCode
