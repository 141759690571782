import pipe from 'lodash/fp/pipe'
import isEmpty from 'lodash/isEmpty'
import fromPairs from 'lodash/fromPairs'

/**
 * Elimina las entradas de un objeto donde los valores sean `undefined` o vacíos.
 * Utiliza una canalización funcional (`pipe`) para transformar el objeto.
 *
 * @function
 * @param {Object} object - El objeto del cual se eliminarán las entradas con valores `undefined` o vacíos.
 * @returns {Object} - Un nuevo objeto sin las entradas que contienen valores `undefined` o vacíos.
 *
 * @example
 * // Ejemplo simple en un archivo JS/TS
 * const obj = { name: 'Juan', age: undefined, country: '' };
 * const cleanObj = deleteUndefinedEntries(obj);
 * // Retorna: { name: 'Juan' }
 *
 * @example
 * // Uso dentro de un componente React
 * const ExampleComponent = () => {
 *   const formData = { username: 'john', password: undefined, role: '' };
 *   const cleanData = deleteUndefinedEntries(formData);
 *
 *   return <pre>{JSON.stringify(cleanData, null, 2)}</pre>;
 *   // Renderiza: { "username": "john" }
 * };
 *
 * @example
 * // Cuando el objeto está completamente limpio
 * const obj = { name: 'Maria', age: 30 };
 * const cleanObj = deleteUndefinedEntries(obj);
 * // Retorna: { name: 'Maria', age: 30 }
 */
export const deleteUndefinedEntries = object =>
  pipe(
    Object.entries, // Convierte el objeto en pares [clave, valor]
    o => o.filter(([, value]) => value !== undefined && !isEmpty(value)), // Filtra valores no definidos o vacíos
    fromPairs // Convierte de nuevo a un objeto
  )(object)
