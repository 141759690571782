const API_KEY = 'AIzaSyBlsdvAtbLl_ClIBb_92QTKJmX-57s2w0g'

/**
 * Crea un servicio de Google Maps basado en una URL base y clave API.
 *
 * @param {string} baseUrl - La URL base del servicio de Google Maps.
 * @param {string} [version='v1'] - La versión de la API de Google Maps (opcional).
 * @returns {Function} - Una función que acepta una clave API y retorna los métodos del servicio.
 *
 * @example
 * const googleMapsService = createGoogleMapsService('https://maps.googleapis.com/maps/api', 'v3')(API_KEY);
 */
const createGoogleMapsService = baseUrl => apiKey => ({
  /**
   * Realiza una petición `fetch` a un servicio de Google Maps.
   *
   * @param {string} endpoint - El endpoint específico del servicio.
   * @param {Object} params - Los parámetros para la petición.
   * @returns {Promise<Object>} - Una promesa que se resuelve con los datos de la API.
   *
   * @example
   * googleMapsService.fetchFromApi('geocode/json', { address: '1600 Amphitheatre Parkway' })
   *   .then(response => console.log(response));
   */
  // eslint-disable-next-line no-unused-vars
  fetchFromApi: async (endpoint, { version, ...params }) => {
    const query = new URLSearchParams({
      ...params,
      key: apiKey
    }).toString()
    try {
      const response = await fetch(`${baseUrl}/${endpoint}?${query}`)
      const data = await response.json()

      return data
    } catch (error) {
      throw String(error)
    }
  }
})

/**
 * Servicio de Geocode de Google Maps.
 *
 * @param {Object} googleMapsService - El servicio base de Google Maps que contiene `fetchFromApi`.
 * @param {string} [version='3'] - La versión del servicio de geocodificación.
 * @returns {Object} - Un objeto con los métodos para geocodificación.
 *
 * @example
 * const geocodeService = createGeocodeService(googleMapsService, 'v3');
 * geocodeService.getCoordinatesByAddress({ address: 'Av. El Salto 4000, Recoleta' })
 *   .then(coordinates => console.log(coordinates));
 */
const createGeocodeService = (googleMapsService, version = 'beta') => ({
  /**
   * Obtiene las coordenadas por dirección usando el servicio de Geocode.
   *
   * @param {Object} params - Parámetros para la solicitud.
   * @param {string} params.address - La dirección completa a geocodificar.
   * @returns {Promise<Object>} - Una promesa que resuelve con los datos de geocodificación.
   *
   * @example
   * const response = await geocodeService.getCoordinatesByAddress({ address: 'Av. El Salto 4000, Recoleta' });
   * console.log(response);
   * // Resultado esperado:
   * // {
   * //   "results": [
   * //     {
   * //       "geometry": {
   * //         "location": {
   * //           "lat": -33.3973742,
   * //           "lng": -70.6277887
   * //         }
   * //       }
   * //     }
   * //   ],
   * //   "status": "OK"
   * // }
   */
  getCoordinatesByAddress: async ({ address }) => {
    return googleMapsService.fetchFromApi(`geocode/json`, {
      address,
      region: 'es-CL',
      Language: 'es',
      version
    })
  }
})

/**
 * Servicio de Places de Google Maps.
 *
 * @param {Object} googleMapsService - El servicio base de Google Maps que contiene `fetchFromApi`.
 * @param {string} [version='v1'] - La versión del servicio de Places.
 * @returns {Object} - Un objeto con los métodos para buscar lugares.
 *
 * @example
 * const placesService = createPlacesService(googleMapsService, 'v3');
 * placesService.searchNearbyPlaces({ location: '37.7749,-122.4194', radius: 500, type: 'restaurant' })
 *   .then(places => console.log(places));
 */
const createPlacesService = (googleMapsService, version = 'alpha') => ({
  /**
   * Busca lugares cercanos a una ubicación específica.
   *
   * @param {Object} params - Parámetros para la solicitud.
   * @param {string} params.location - La latitud y longitud del lugar.
   * @param {number} params.radius - El radio de búsqueda en metros.
   * @param {string} params.type - El tipo de lugar que se busca (ej. "restaurant").
   * @returns {Promise<Object>} - Una promesa que resuelve con los datos de lugares.
   *
   * @example
   * const response = await placesService.searchNearbyPlaces({ location: '37.7749,-122.4194', radius: 500, type: 'restaurant' });
   * console.log(response);
   * // Resultado esperado:
   * // {
   * //   "results": [
   * //     {
   * //       "name": "Great Restaurant",
   * //       "geometry": {
   * //         "location": {
   * //           "lat": 37.7749,
   * //           "lng": -122.4194
   * //         }
   * //       }
   * //     }
   * //   ],
   * //   "status": "OK"
   * // }
   */
  searchNearbyPlaces: async ({ location, radius, type }) => {
    return googleMapsService.fetchFromApi('place/nearbysearch/json', {
      location,
      radius,
      type,
      version
    })
  }
})

/**
 * Servicio de Street View de Google Maps.
 *
 * @param {Object} googleMapsService - El servicio base de Google Maps que contiene `fetchFromApi`.
 * @param {string} [version='3'] - La versión del servicio de Street View.
 * @returns {Object} - Un objeto con los métodos para obtener imágenes de Street View.
 *
 * @example
 * const streetViewService = createStreetViewService(googleMapsService, 'v3');
 * streetViewService.getStreetViewImage({ location: '37.7749,-122.4194', size: '600x400' })
 *   .then(imageUrl => console.log(imageUrl));
 */
const createStreetViewService = (googleMapsService, version = '3') => ({
  /**
   * Obtiene una imagen de Street View para una ubicación específica.
   *
   * @param {Object} params - Parámetros para la solicitud.
   * @param {string} params.location - La latitud y longitud del lugar.
   * @param {string} params.size - El tamaño de la imagen en formato 'WIDTHxHEIGHT' (ej. '600x400').
   * @returns {Promise<string>} - Una promesa que resuelve con la URL de la imagen de Street View.
   *
   * @example
   * const imageUrl = await streetViewService.getStreetViewImage({ location: '37.7749,-122.4194', size: '600x400' });
   * console.log(imageUrl);
   */
  getStreetViewImage: async ({ location, size }) => {
    return googleMapsService.fetchFromApi('streetview', {
      location,
      size,
      region: 'es-CL',
      Language: 'es',
      version
    })
  }
})

/**
 * Inicializa todos los servicios de Google Maps, cada uno con su propia versión si es necesario.
 *
 * @param {string} apiKey - La clave API para todos los servicios.
 * @param {Object} versions - Un objeto con las versiones para cada servicio.
 * @returns {Object} - Un objeto con todos los servicios de Google Maps.
 *
 * @example
 * const services = initializeGoogleMapsServices(API_KEY, { geocode: 'v3', places: 'v2', streetView: 'v1' });
 * const geocodeResponse = await services.geocodeService.getCoordinatesByAddress({
 *   address: 'Av. El Salto 4000, Recoleta, Región Metropolitana, Chile'
 * });
 * console.log(geocodeResponse);
 */
const initializeGoogleMapsServices = (apiKey, versions = {}) => {
  const { geocode = '3', places = '3', streetView = '3' } = versions

  const googleMapsService = createGoogleMapsService(
    'https://maps.googleapis.com/maps/api',
    'v1' // Versión general por defecto si no se especifica
  )(apiKey)

  return {
    geocodeService: createGeocodeService(googleMapsService, geocode),
    placesService: createPlacesService(googleMapsService, places),
    streetViewService: createStreetViewService(
      googleMapsService,
      streetView
    )
  }
}

// Inicializar los servicios con la API Key y versiones específicas para cada servicio
const mapServices = initializeGoogleMapsServices(API_KEY, {
  geocode: 'v3',
  places: 'v2',
  streetView: 'v1'
})
/**
 * Obtiene las coordenadas por dirección usando el servicio de Geocode.
 *
 * @param {Object} params - Parámetros para la solicitud.
 * @param {string} params.address - La dirección completa a geocodificar.
 * @returns {Promise<Object>} - Una promesa que resuelve con los datos de geocodificación.
 *
 * @example
 * const response = await fetchGoordinates({ address: 'Av. El Salto 4000, Recoleta' });
 * console.log(response);
 * // Resultado esperado:
 * // {
 * //   "results": [
 * //     {
 * //       "geometry": {
 * //         "location": {
 * //           "lat": -33.3973742,
 * //           "lng": -70.6277887
 * //         }
 * //       }
 * //     }
 * //   ],
 * //   "status": "OK"
 * // }
 */
export const fetchGoordinates = async ({ address }) =>
  await mapServices.geocodeService.getCoordinatesByAddress({ address })

export default mapServices
