import { fetchDecodeOrder } from '../client/fetchDecoded'
import persistentStore from '../persistentStore'

const subOrderService = (baseUrl, endpoint) => ({
  exportOrderAllData: async ({
    fromDate = undefined,
    toDate = undefined,
    orderId = undefined,
    signal = undefined
  }) => {
    let queryParams = ''
    if (fromDate) {
      queryParams += `&fromDate=${fromDate}`
    }
    if (toDate) {
      queryParams += `&toDate=${toDate.replace('00:00:00', '23:59:59')}`
    }

    if (orderId) {
      queryParams += `&search=${encodeURIComponent(orderId)}`
    }

    const response = await fetchDecodeOrder(
      `${baseUrl}/${endpoint}/download?${queryParams}`,
      {
        method: 'GET',
        headers: {
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        },
        signal
      }
    )

    return response
  },
  orderFilter: async ({
    startDate = undefined,
    endDate = undefined,
    skip = undefined,
    limit = 25,
    orderId = undefined,
    sort = undefined,
    signal
  }) => {
    let queryParams = `limit=${limit}`

    if (startDate) {
      queryParams += `&fromDate=${startDate}`
    }
    if (endDate) {
      queryParams += `&toDate=${endDate.replace('00:00:00', '23:59:59')}`
    }

    if (orderId) {
      queryParams += `&search=${encodeURIComponent(orderId)}`
    }

    if (skip) {
      queryParams += `&skip=${skip}`
    }
    if (sort) {
      const sortOrder = sort.desc ? 'desc' : 'asc'
      queryParams += `&sort=${sort.id}&sortOrder=${sortOrder}`
    }

    const response = await fetchDecodeOrder(
      `${baseUrl}/${endpoint}/search?${queryParams}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        },
        signal
      }
    )
    return response
  },
  storeSearch: async ({ storeName, signal }) => {
    if (!storeName) {
      return
    }
    const response = await fetchDecodeOrder(
      `${baseUrl}/stores/get-by-name/${storeName}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          authorization: persistentStore.getToken(),
          app: process.env.REACT_APP_APPLICATION_NAME
        },
        signal
      }
    )
    return response
  }
})

export default subOrderService(
  process.env.REACT_APP_API_ORDER_PATH,
  'orders'
)
