import isString from 'lodash/isString'

/**
 * Capitaliza las palabras de un nombre o título, excepto las que se encuentran en la lista de palabras excluidas.
 * Las palabras excluidas se mantienen en minúsculas, mientras que el resto tienen la primera letra en mayúscula
 * y el resto de letras en minúscula.
 *
 * @function
 * @param {string} title - El nombre o título a capitalizar.
 * @returns {string} - El nombre capitalizado. Si el título no es una cadena válida, se retorna una cadena vacía.
 *
 * @example
 * // Ejemplo con un nombre simple
 * capitalizeName('juan de la cruz');
 * // Retorna: 'Juan de la Cruz'
 *
 * @example
 * // Título con varias palabras excluidas
 * capitalizeName('el libro de los secretos');
 * // Retorna: 'El Libro de los Secretos'
 *
 * @example
 * // Si el parámetro no es una cadena
 * capitalizeName(12345);
 * // Retorna: ''
 *
 * @example
 * // Capitalización de un título en inglés
 * capitalizeName('the lord of the rings');
 * // Retorna: 'The Lord of the Rings'
 */
const capitalizeName = title => {
  // Lista de palabras comunes que no se capitalizan
  const excludeWords = [
    'de',
    'del',
    'y',
    'o',
    'a',
    'en',
    'el',
    'la',
    'los',
    'las',
    'un',
    'una',
    'unos',
    'unas',
    'con',
    'por',
    'para',
    'the',
    'of',
    'and',
    'or',
    'in',
    'on'
  ]

  return isString(title)
    ? title
        .toLowerCase()
        .split(' ')
        .map(
          word =>
            excludeWords.includes(word)
              ? word // Si es una palabra excluida, la deja en minúsculas
              : word.charAt(0).toUpperCase() + word.slice(1) // Capitaliza la primera letra del resto de palabras
        )
        .join(' ')
    : '' // Si no es un string, retorna una cadena vacía
}

export default capitalizeName
