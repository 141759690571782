import React, { Fragment } from 'react'
import { string, arrayOf } from 'prop-types'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import { useNavigate } from 'react-router-dom'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import useMediaQuery from '@mui/material/useMediaQuery'

import Card from '../../atoms/Card'
import RowHead from '../../atoms/BaseTable/RowHead'
import CellHeader from '../../atoms/BaseTable/BaseCell/CellHeader/CellHeader'
import Title from '../../atoms/BaseTable/Typography/Title'
import TextHeader from '../../atoms/BaseTable/Typography/TextHeader'
import CellEmpty from '../../atoms/BaseTable/BaseCell/CellEmpty'
import CellTooltip from '../../atoms/BaseTable/BaseCell/CellTooltip'
import ArrowSort from '../../molecules/ArrowSort'
import Tooltip from '../../atoms/ToolTip/ToolTip'
import ButtonTable from '../../atoms/ButtonTable'

const isEmpty = value =>
  typeof value === 'undefined' || value === '' || value === null

const Container = styled('div')`
  margin-bottom: 3%;
`

const TableSort = styled(TableSortLabel)`
  flex-direction: row-reverse;
`

const TitleTable = styled(Title)`
  @media (max-width: 700px) {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }
`

const ContainerTitle = styled('div')`
  margin-top: 1%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4%;
  }
`
const TableTemplate = ({ query, columns, data }) => {
  const navigate = useNavigate()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useSortBy
  )
  const match = useMediaQuery('(min-width:1100px)')
  return (
    <Container key="table">
      <ContainerTitle>
        <div>
          <TitleTable>Resultados para: “{query}”</TitleTable>
        </div>
        {match && (
          <div>
            <ButtonTable
              onClick={() => navigate('/pedidos')}
              type="button"
              label="Ver pedidos"
            />
          </div>
        )}
      </ContainerTitle>
      <TableContainer
        component={Card}
        data-testid="TableTemplate__TableContainer"
      >
        <Table
          aria-label="tracking-table"
          {...getTableProps()}
          stickyHeader={true}
        >
          <TableHead>
            {headerGroups.map(headerGroup => (
              <RowHead
                data-testid="TableTemplate__RowHead"
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => (
                  <CellHeader
                    align={index === 0 ? 'center' : 'inherit'}
                    key={column.id}
                    {...column.getHeaderProps(
                      column.getSortByToggleProps()
                    )}
                    sortDirection={column.isSortedDesc ? 'desc' : 'asc'}
                  >
                    <TableSort
                      data-testid={`TableTemplate__TableSortLabel--${index}`}
                      title="Ordernar por"
                      active={column?.isSorted}
                      IconComponent={() =>
                        column.isSorted ? (
                          <ArrowSort sort={String(column?.isSortedDesc)} />
                        ) : index === 2 || index === 3 ? (
                          <ArrowSort sort={String(column?.isSortedDesc)} />
                        ) : null
                      }
                      direction={column.isSortedDesc ? 'desc' : 'asc'}
                    >
                      <TextHeader>{column.render('Header')}</TextHeader>
                    </TableSort>
                  </CellHeader>
                ))}
              </RowHead>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)

              return (
                <Fragment key={String(row.id)}>
                  <Tooltip
                    title={`Ver despacho ${row.original.trackingId}`}
                    followCursor
                  >
                    <TableRow
                      hover
                      {...row.getRowProps()}
                      onClick={() =>
                        navigate(
                          `${encodeURIComponent(row.original.trackingId)}?pedido=${encodeURIComponent(row.original.orderId)}`,
                          {
                            state: row?.original
                          }
                        )
                      }
                      style={{ cursor: 'pointer' }}
                    >
                      {row.cells.map((cell, index) => {
                        return isEmpty(cell.value) ? (
                          <CellEmpty key={index + cell.row.id} />
                        ) : (
                          <CellTooltip
                            index={index}
                            key={index + cell.value}
                          >
                            {cell.render('Cell')}
                          </CellTooltip>
                        )
                      })}
                    </TableRow>
                  </Tooltip>
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}
TableTemplate.propTypes = {
  query: string,
  columns: arrayOf(Object),
  data: arrayOf(Object)
}
TableTemplate.defaultProps = {
  query: '',
  columns: [],
  data: []
}
export default TableTemplate
