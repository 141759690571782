import React, { memo } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import Tooltip from '../ToolTip/ToolTip'

const Text = styled('p')`
  font-style: normal;
  font-weight: 300;
  font-size: ${({ size }) => (size === 'large' ? '16px' : '11px')};
  line-height: 11px;
  letter-spacing: 0.16px;
  color: ${({ stepstatus }) =>
    stepstatus === 'shipping' || stepstatus === 'store'
      ? '#ffffff'
      : '#000000'};
  background-color: ${({ status }) => status || '#C4C4C4'};
  height: ${({ size }) => (size === 'large' ? '30px' : '20px')};
  padding-top: ${({ size }) => (size === 'large' ? '10px' : '4.8px')};
  padding-bottom: ${({ size }) => (size === 'large' ? '10px' : '4.8px')};
  border-radius: 8px;
  padding-left: ${({ size }) => (size === 'large' ? '14px' : '7.2px')};
  padding-right: ${({ size }) => (size === 'large' ? '14px' : '7.2px')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: context-menu;
`

const colors = new Map([
  ['success', ' #92E6B5'],
  ['warning', '#FFC96B'],
  ['error', '#FC8E8E'],
  ['pending', '#C4C4C4'],
  ['store', '#4590B9'],
  ['shipping', '#485A64']
])

const Background = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Badget = memo(
  ({ title, status, description, style, size = 'small' }) => {
    return (
      <Tooltip
        followCursor={true}
        title={description}
        disabled={
          isEmpty(description) || !isString(description) || !isString(size)
        }
      >
        <Background data-testid="Badget">
          <Text
            status={colors.get(status)}
            stepstatus={status}
            size={size}
            style={{ ...style }}
          >
            {title}
          </Text>
        </Background>
      </Tooltip>
    )
  },
  (prevProps, nextProps) => prevProps.title === nextProps.title
)

Badget.propTypes = {
  title: PropTypes.string.isRequired,
  status: PropTypes.string,
  size: PropTypes.string,
  description: PropTypes.string,
  colorFont: PropTypes.string,
  style: PropTypes.object
}
Badget.defaultProps = {
  status: 'pending',
  title: 'No asignado',
  description: '',
  size: 'small',
  colorFont: '#000000',
  style: {}
}
Badget.displayName = 'Badget'

export default Badget
