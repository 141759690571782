import { createSlice } from '@reduxjs/toolkit'
import fetchCoordinatesByAddress from './fetchCoordinatesByAddress'

export const mapAddressSlice = createSlice({
  name: 'map',
  initialState: {
    status: 'idle',
    coordinates: { lat: null, lng: null },
    fullAddress: '',
    placeId: ''
  },
  reducers: {
    resetMap(state) {
      state.status = 'idle'
      state.coordinates = { lat: null, lng: null }
      state.fullAddress = ''
      state.placeId = ''
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchCoordinatesByAddress.pending, state => {
      state.status = 'loading'
    })
    builder.addCase(fetchCoordinatesByAddress.rejected, state => {
      state.status = 'error'
    })
    builder.addCase(
      fetchCoordinatesByAddress.fulfilled,
      (state, { payload }) => {
        state.coordinates = payload.coordinates
        state.fullAddress = payload.fullAddress
        state.placeId = payload.placeId
        state.status = 'finished'
      }
    )
  }
})

export const { resetMap } = mapAddressSlice.actions
const reducer = mapAddressSlice.reducer
export default reducer
