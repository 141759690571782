/* eslint-disable no-console */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchGoordinates } from '../../../data/services/mapServices'

const fetchCoordinatesByAddress = createAsyncThunk(
  'map/AddressToCoordinates',
  async ({ fullAddress }, { rejectWithValue }) => {
    try {
      const data = await fetchGoordinates({ address: fullAddress })

      if (!data || data?.status !== 'OK') {
        return rejectWithValue('Error del servidor')
      }

      return {
        coordinates: data?.results[0]?.geometry?.location,
        fullAddress: data?.results[0]?.formatted_address,
        placeId: data?.results[0]?.place_id
      }
    } catch (error) {
      return rejectWithValue(error.message)
    }
  }
)
export default fetchCoordinatesByAddress
