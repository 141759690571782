import { deleteUndefinedEntries } from '../../utils/deleteUndefinedEntries'

/**
 * Obtiene la primera imagen del producto que coincide con un color específico.
 *
 * @param {Object} product - El objeto del producto que se está evaluando.
 * @param {string} color - El color que se utilizará para filtrar las opciones del producto.
 * @returns {Object|null} - Un objeto con la URL y el texto alternativo de la imagen si se encuentra,
 *                          de lo contrario, devuelve `null`.
 *
 * @example
 * const product = {
 *   selectedOptions: [
 *     { name: 'color', value: 'red' }
 *   ],
 *   media: {
 *     edges: [
 *       {
 *         node: {
 *           alt: 'Image of a red product',
 *           preview: {
 *             image: {
 *               url: 'http://example.com/red-product.jpg',
 *               altText: 'Red Product'
 *             }
 *           }
 *         }
 *       }
 *     ]
 *   }
 * };
 * const image = getFirstImageByColor(product, 'red');
 * console.log(image); // { url: 'http://example.com/red-product.jpg', alt: 'Red Product' }
 */
function getFirstImageByColor(product, color) {
  const selectedOption = product?.selectedOptions?.find(
    option => option?.name === 'color' && option?.value === color
  )
  if (!selectedOption) return null

  const mediaImage = product.media.edges.find(edge =>
    edge?.node?.alt?.includes(`#${color}`)
  )
  return Object.freeze({
    url: mediaImage ? mediaImage.node?.preview?.image?.url : null,
    alt: mediaImage ? mediaImage.node?.preview?.image?.altText : null
  })
}

/**
 * Normaliza una lista de productos, transformando su estructura para facilitar el uso en la aplicación.
 *
 * @param {Array<Object>} products - Un arreglo de objetos de productos que se desea normalizar.
 * @returns {Array<Object>} - Un nuevo arreglo de productos normalizados, cada uno con una estructura consistente.
 *
 * @example
 * const products = {
 *   edges: [
 *     {
 *       node: {
 *         id: '1',
 *         price: 100,
 *         compareAtPrice: 150,
 *         product: {
 *           tags: ['brand_nike', 'seller_amazon'],
 *           productType: 'Shoes',
 *           onlineStorePreviewUrl: 'http://example.com/preview',
 *           onlineStoreUrl: 'http://example.com/store',
 *           featuredImage: {
 *             url: 'http://example.com/default-image.jpg'
 *           }
 *         },
 *         selectedOptions: [{ name: 'color', value: 'red' }],
 *         sku: 'SKU123'
 *       }
 *     }
 *   ]
 * };
 * const normalized = normalizeProducts(products);
 * console.log(normalized);
 */
const normalizeProducts = products => {
  const newProducts = products?.edges?.map(({ node }) => {
    const sellerTag = node.product.tags.find(tag => tag.includes('seller'))
    const seller = sellerTag?.split('_')[1]

    const brandTag = node.product.tags.find(tag => tag.includes('brand'))
    const brand = brandTag?.split('_')[1]

    const color = node.selectedOptions.find(
      option => option?.name === 'color'
    )?.value
    const mediaImage = getFirstImageByColor(node, color)

    const ownSkuNode = node?.metafields?.edges?.find(
      edge =>
        edge?.node?.key === 'sku_propio' || edge?.node?.key === 'own_sku'
    )
    const ownSku = ownSkuNode ? ownSkuNode.node.value : null

    return deleteUndefinedEntries({
      ...node,
      compareAtPrice: node?.compareAtPrice,
      price: node?.price,
      tags: node?.product?.tags,
      category: node?.product.productType,
      id: node?.id,
      idProduct: node?.product?.id,
      color: color ?? node?.title,
      onlineStorePreviewUrl: node?.product?.onlineStorePreviewUrl,
      onlineStoreUrl: node?.product?.onlineStoreUrl,
      image: mediaImage
        ? { ...mediaImage }
        : { ...node?.product?.featuredImage },
      featuredImageUrl: node?.product?.featuredImage?.url,
      brand: brand ?? node?.brand ?? node?.product?.vendor,
      seller: seller ?? node?.seller,
      sku: node?.sku,
      ownSku
    })
  })
  return newProducts
}

export default normalizeProducts
