import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import join from 'lodash/fp/join'
import destinationMapper from '../../mappers/destinationMapper'
import capitalizeName from '../../utils/capitalizeName'
import { deleteUndefinedEntries } from '../../utils/deleteUndefinedEntries'

/**
 * Transforma un texto dado:
 * - Convierte el texto en un string.
 * - Elimina espacios en blanco del principio y el final.
 * - Convierte el texto a minúsculas.
 * - Aplica capitalización a las palabras, excluyendo palabras comunes.
 *
 * @param {string} [text=''] - El texto que se desea transformar.
 * @returns {string} - El texto transformado con capitalización adecuada.
 *
 * @example
 * const result = transformText('  dirección DE ejemplo ');
 * console.log(result); // 'Dirección de Ejemplo'
 */
const transformText = (text = '') =>
  pipe(
    text => String(text), // Asegura que el texto es un string
    text => text.trim().trimEnd(), // Elimina espacios en blanco al principio y al final
    text => text?.toLowerCase(), // Convierte el texto a minúsculas
    capitalizeName // Capitaliza palabras importantes
  )(text)

/**
 * Normaliza y transforma los datos de destino:
 * - Elimina entradas indefinidas.
 * - Mapea la región del destino usando un mapper.
 * - Concatena los campos de dirección en una sola cadena.
 *
 * @param {Object} destination - El objeto que contiene los datos de destino.
 * @param {string} destination.destination_address1 - Dirección principal del destino.
 * @param {string} [destination.destination_address2] - Dirección secundaria (opcional).
 * @param {string} destination.destination_county - Condado o municipio del destino.
 * @param {string} destination.destination_region - Región del destino.
 * @returns {string} - La dirección completa del destino, formateada y separada por comas.
 *
 * @example
 * const destination = {
 *   destination_address1: 'Calle 123',
 *   destination_address2: 'Apto 456',
 *   destination_county: 'Condado Ejemplo',
 *   destination_region: 'RegionX'
 * };
 * const formattedDestination = getDestination(destination);
 * console.log(formattedDestination);
 * // 'Calle 123, Apto 456, Condado Ejemplo, Región X'
 */
const getDestination = ({
  destination_address1,
  destination_address2,
  destination_county,
  destination_region
}) =>
  pipe(
    deleteUndefinedEntries, // Elimina valores undefined o vacíos del objeto
    ({ destination_region, ...rest }) => ({
      ...rest,
      destination_region: destinationMapper.get(destination_region) // Mapea la región con su valor correspondiente
    }),
    Object.values, // Convierte los valores del objeto en un array
    map(transformText), // Aplica transformaciones al texto de cada campo
    join(', ') // Junta los campos en una sola cadena separada por comas
  )({
    destination_address1,
    destination_address2,
    destination_county,
    destination_region
  })

export default getDestination
