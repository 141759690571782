/**
 * Verifica si la lista de permisos contiene un permiso específico para la aplicación actual.
 *
 * @param {Array<Object>} [permissions=[]] - Una lista de permisos que se desea verificar.
 * Cada objeto de permiso debe contener un campo `appId`.
 *
 * @returns {boolean} - Devuelve `true` si se encuentra un permiso válido para la aplicación,
 * de lo contrario devuelve `false`.
 *
 * @example
 * const permissions = [
 *   { appId: '123' },
 *   { appId: '456' },
 *   { appId: '789' }
 * ];
 * const hasPermission = checkPermission(permissions);
 * console.log(hasPermission); // true si REACT_APP_APP_ID es '123', de lo contrario false
 */
export const checkPermission = (permissions = []) => {
  if (typeof permissions === 'undefined') return false
  if (permissions === null) return false
  if (!permissions) return false
  if (permissions.length === 0) return false

  const appId = process.env.REACT_APP_APP_ID.toString()

  const hasPermission = permissions?.some(
    permission => permission?.appId?.toString() === appId
  )

  return hasPermission
}
